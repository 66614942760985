const CARD = {
    code: "",
    date: {
        activatedOn: null,
        expiresOn: null,
        issuedOn: null,
        validFrom: null,
        voidedOn: null,
    },
    ledger: [],
    metadata: {
        description: "",
        subtitle: "",
        term: "",
        title: "",
    },
    status: "",
    validity_time: "",
    value: {
        backing: null,
        equivalentValuePerUnit: null,
        initialUnits: null,
        initialValue: null,
        remainingUnits: null,
        remainingValue: null,
    },
    buyer_information: {
        email: null,
        gift: {
            isGift: false,
            recipientEmail: null,
            recipientId: null,
            recipientName: null,
        },
        id: null,
        name: null,
        phone: null,
    },
};

export { CARD };