import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
// import { CARD } from '../../data/card'
import { firebase } from "../../config/firebase";
import { QrReader } from "react-qr-reader";
import { setError } from "../../global/actions";
import VerificationInput from "react-verification-input";
import { Player } from "@lottiefiles/react-lottie-player";

export default function ActionCard() {
  const actionCard = useSelector((state) => state.actionCard);
  const validateBranchReducer = useSelector(
    (state) => state.validateBranchReducer
  );
  const navigate = useNavigate();
  const [code, setCode] = useState("");
  const [pin, setPin] = useState("");
  const [pinCard, setPinCard] = useState("");
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const handleScan = (data) => {
    setLoading(true);

    if (data) {
      let split = data.split("-");
      if (actionCard !== 2) {
        if (split.length !== 3) {
          // navigate("/");
          alert("Ingrese un código válido");
          setLoading(false);
        } else {
          const validCardPin = firebase
            .app()
            .functions("us-central1")
            .httpsCallable("validCardPinClient");
          validCardPin({
            client: split[0],
            idLote: split[1],
            code: split[2],
            pin: pin,
            pinCard: pinCard,
            id: validateBranchReducer.id,
          })
            .then((res1) => {
              if (actionCard === 0) {
                navigate(`/${data}`);
              } else if (actionCard === 1) {
                navigate(`/activar/${data}`);
              }
              setLoading(false);
            })
            .catch((err) => {
              dispatch(setError("La tarjeta ingresada no es valida"));
              navigate("/error");
              setLoading(false);
            });
        }
      } else {
        if (split.length !== 2) {
          // navigate("/");
          alert("Ingrese un código válido");
          setLoading(false);
        } else {
          const validPartnerLote = firebase
            .app()
            .functions("us-central1")
            .httpsCallable("validPartnerLote");
          validPartnerLote({
            id: validateBranchReducer.id,
            lote: `${split[0]}-${split[1]}`,
          })
            .then((res) => {
              if (res.data) {
                navigate(`/activar/${data}`);
              } else {
                alert("La tarjeta no pertenece a la sucursal");
              }
              setLoading(false);
            })
            .catch((err) => {
              dispatch(setError("El lote ingresado no es valido"));
              navigate("/error");
              setLoading(false);
            });
        }
      }
    }
  };

  useEffect(() => {
    // if (validateBranchReducer)
    // console.log(validateBranchReducer.code === undefined)
    if (validateBranchReducer.code === undefined) navigate("/");
  }, []);

  return (
    <main className="flex flex-col items-center px-3 my-6">
      <div className="card p-4 w-full md:w-1/2">
        {loading ? (
          <section className="flex justify-center my-12">
            <svg
              className="animate-spin -ml-1 mr-3 h-10 w-10 text-blue"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          </section>
        ) : (
          <>
            {actionCard === 0 && (
              <h1 className="text-center font-bold text-2xl mb-3">
                Detalle de tarjeta
              </h1>
            )}
            {actionCard === 1 && (
              <h1 className="text-center font-bold text-2xl mb-3">
                Canjear tarjeta
              </h1>
            )}
            <p className="text-center">
              Escanee el código QR o ingrese el código de la tarjeta
            </p>

            <div className="relative">
              <QrReader
                className="w-full border rounded-xl my-4 max-w-lg mx-auto"
                delay={300}
                onResult={(result, error) => {
                  if (!!result) {
                    let reg = /^https?:\/\/[\w\-]+(\.[\w\-]+)+[/#?]/;
                    let code = result.text.replace(reg, "");
                    setCode(code);
                  }

                  // if (!!error) {
                  //     handleError(error);
                  //     console.info(error);
                  // }
                }}
              />

              <div className="absolute top-0 left-0 w-full h-full">
                <div className="flex justify-center items-center h-full">
                  <Player
                    autoplay
                    src="https://assets3.lottiefiles.com/packages/lf20_q30c1wrm.json"
                    // src="https://assets6.lottiefiles.com/packages/lf20_uwpcqber.json"
                    style={{ height: "300px", width: "300px" }}
                  ></Player>
                </div>
              </div>
            </div>

            <div className="form-group">
              <label className="form-label">Código de la tarjeta</label>
              <input
                className="form-control max-w-lg"
                value={code}
                onChange={(e) => setCode(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label className="form-label">PIN de la Tarjeta</label>
              <VerificationInput
                classNames={{
                  character: "form-control",
                  container: "h-[65px]",
                }}
                placeholder=""
                validChars="0-9"
                length={6}
                onChange={(e) => setPinCard(e)}
              />
            </div>
            <div className="form-group">
              <label className="form-label">PIN de Sucursal</label>
              <VerificationInput
                classNames={{
                  character: "form-control",
                  container: "h-[65px]",
                }}
                placeholder=""
                validChars="0-9"
                length={4}
                onChange={(e) => setPin(e)}
              />
            </div>

            <button
              className="btn-primary block md:mx-auto w-full max-w-lg"
              disabled={loading}
              onClick={() => handleScan(code)}
            >
              Validar
            </button>
          </>
        )}
      </div>

      <Link to="/acciones" className="link-primary mt-4">
        Volver
      </Link>
    </main>
  );
}
