import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { CARD } from "../../data/card";
import { firebase } from "../../config/firebase";
import { setError } from "../../global/actions";
// import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

// export const CardDetails = () => {
export default function ActiveCard() {
  const { id } = useParams();
  const validateBranchReducer = useSelector(
    (state) => state.validateBranchReducer
  );
  const [card, setCard] = useState(false);
  const [cardInfo, setCardInfo] = useState(CARD);
  const [type, setType] = useState("");
  const actionCard = useSelector((state) => state.actionCard);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let loading = true;

  const loadDataOnlyOnce = () => {
    console.log(id);
    if (isLoading) {
      let info = id.split("-");
      if (actionCard === 1) {
        setType("TARJETA");
        if (info.length !== 3) {
          navigate("/");
        } else {
          const activateCard = firebase.functions().httpsCallable("redeemCard");

          activateCard({
            client: info[0],
            partner_id_i: validateBranchReducer.id,
            branchId: validateBranchReducer.code,
            partnerId: validateBranchReducer.id_doc,
            partner_id: validateBranchReducer.id_doc,
            lot: info[1],
            quantity: 1,
            cardId: info[2],
          })
            .then((res) => {
              console.log(res);
              setCard(res.data.status);
              setCardInfo(res.data.data);
              setIsLoading(false);
            })
            .catch((err) => {
              console.log(err);
              setIsLoading(false);
              dispatch(setError("Ocurrió un error activando la tarjeta"));
              navigate("/error");
            });
        }
      } else if (actionCard === 2) {
        setType("LOTE");
        if (info.length !== 2) {
          navigate("/");
        } else {
          const distributeLot = firebase
            .functions()
            .httpsCallable("distributeLot");

          distributeLot({
            client: info[0],
            partner_id_i: info[2],
            quantityquantity: 1,
          })
            .then((res) => {
              console.log(res);
              setCard(res.data);
              setIsLoading(false);
            })
            .catch((err) => {
              console.log(err);
              setIsLoading(false);
              dispatch(setError("Ocurrió un error activando el lote"));
              navigate("/error");
            });
        }
      }
    }
  };

  useEffect(() => {
    if (validateBranchReducer.code === undefined) navigate("/");
    if (loading) {
      loading = false;
      setIsLoading(false);
      loadDataOnlyOnce();
    }
  }, []);

  return (
    // <div>
    //     <div style={{ display: "flex", flexDirection: 'row', justifyContent: 'center' }}>
    <main className="flex flex-col items-center px-3 py-6">
      <div className="card w-full md:w-1/2">
        {card && (
          <div>
            {/* <h1 style={{}}>{type} ACTIVADA</h1> */}
            {cardInfo.code !== "" && (
              // <div style={{ padding: 50 }}>
              //     <div style={{ display: "flex", flexDirection: 'row', justifyContent: 'space-between' }}>
              //         <div>Estado: {cardInfo.status}</div>
              //         <div>Código: {cardInfo.code}</div>
              //         <span>Duración: {cardInfo.validity_time} días</span>
              //     </div>
              //     <div style={{ marginTop: 15 }}>
              //         <div>Titulo: {cardInfo.metadata.title}</div>
              //         <div>Subtitulo: {cardInfo.metadata.subtitle}</div>
              //         <span>Descripción: {cardInfo.metadata.description}</span>
              //     </div>
              //     <div style={{ display: "flex", flexDirection: 'row', justifyContent: 'space-between', marginTop: 15 }}>
              //         {cardInfo.value.backing === "units" ? (
              //             <div>
              //                 <div>
              //                     Total de Unidades {cardInfo.value.initialUnits}
              //                 </div>
              //                 <div>
              //                     Valor de Unidad $ {cardInfo.value.equivalentValuePerUnit}
              //                 </div>
              //                 <div>
              //                     Unidades Restantes {cardInfo.value.remainingUnits}
              //                 </div>
              //             </div>
              //         ) : (
              //             <div>
              //                 <div>Monto $ {cardInfo.value.initialValue}</div>
              //                 <div>
              //                     Monto Restantes $ {cardInfo.value.remainingValue}
              //                 </div>
              //             </div>
              //         )}
              //     </div>
              //     <div style={{ marginTop: 15 }}>
              //         <div>Usuario que adquirió la tarjeta:</div>
              //         <div>{cardInfo.buyer_information.name}</div>
              //         <div>{cardInfo.buyer_information.id}</div>
              //         <span>{cardInfo.buyer_information.email}</span>
              //         <span>{cardInfo.buyer_information.phone}</span>
              //         {cardInfo.buyer_information.gift.isGift && (
              //             <div>
              //                 <div>Regalada a:</div>
              //                 <div>{cardInfo.buyer_information.gift.recipientName}</div>
              //                 <div>{cardInfo.buyer_information.gift.recipientId}</div>
              //                 <span>{cardInfo.buyer_information.gift.recipientEmail}</span>
              //             </div>
              //         )}
              //     </div>
              //     {cardInfo.ledger.map((value, index) => (
              //         <ul key={index}>
              //             <li>{value.eventName} - {new Date(value.eventOccuredOn._seconds * 1000).toLocaleString()}</li>
              //         </ul>
              //     ))}
              //     {/* <button style={{ backgroundColor: '#00ff0c', marginLeft: 10, borderRadius: 4, width: 150, height: 50 }} onClick={() => navigate('/confirmar')}>Continuar</button> */}
              // </div>
              <>
                <div className="grid md:grid-cols-2 px-4 pt-4">
                  <h1 className="text-center font-bold text-2xl mb-5 col-span-2">
                    {cardInfo.code}
                  </h1>

                  <div>
                    <div className="mb-3">
                      <h3 className="text-lg uppercase tracking-widest">
                        Adquirido por
                      </h3>
                      <p>{cardInfo.buyer_information.name}</p>
                    </div>

                    <div className="mb-3">
                      <h3 className="text-lg uppercase tracking-widest">C.I</h3>
                      <p>{cardInfo.buyer_information.id}</p>
                    </div>

                    <div className="mb-3">
                      <h3 className="text-lg uppercase tracking-widest">
                        Email
                      </h3>
                      <p>{cardInfo.buyer_information.email}</p>
                    </div>

                    <div className="mb-3">
                      <h3 className="text-lg uppercase tracking-widest">
                        Teléfono
                      </h3>
                      <p>{cardInfo.buyer_information.phone}</p>
                    </div>
                  </div>

                  {cardInfo.buyer_information.gift.isGift && (
                    <div>
                      <div className="mb-3">
                        <h3 className="text-lg uppercase tracking-widest">
                          Regalada a
                        </h3>
                        <p>{cardInfo.buyer_information.gift.recipientName}</p>
                      </div>

                      <div className="mb-3">
                        <h3 className="text-lg uppercase tracking-widest">
                          C.I
                        </h3>
                        <p>{cardInfo.buyer_information.gift.recipientId}</p>
                      </div>

                      <div className="mb-3">
                        <h3 className="text-lg uppercase tracking-widest">
                          Email
                        </h3>
                        <p>{cardInfo.buyer_information.gift.recipientEmail}</p>
                      </div>
                    </div>
                  )}

                  <ul role="list" className="mt-6 col-span-2">
                    {cardInfo.ledger.map((value, index) => (
                      <li key={value.index}>
                        <div className="relative pb-8">
                          {index !== cardInfo.ledger.length - 1 ? (
                            <span
                              className="absolute top-4 left-4 h-full w-0.5 bg-gray-200 -ml-2"
                              aria-hidden="true"
                            />
                          ) : null}

                          <div className="relative flex items-center space-x-4">
                            <div>
                              <span className="h-4 w-4 rounded-full flex items-center justify-center ring-4 bg-blue-500">
                                <div
                                  className="h-2 w-2 bg-blue-500"
                                  aria-hidden="true"
                                ></div>
                              </span>
                            </div>

                            <div className="flex items-center space-x-4 w-full">
                              <p>{value.eventName} </p>

                              <div className="text-right text-sm whitespace-nowrap text-gray-500">
                                <time
                                  dateTime={new Date(
                                    value.eventOccuredOn._seconds * 1000
                                  ).toLocaleString()}
                                >
                                  {new Date(
                                    value.eventOccuredOn._seconds * 1000
                                  ).toLocaleString()}
                                </time>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>

                <div className="bg-blue-900 p-4 text-white mt-5 rounded-b-xl">
                  <h2 className="font-bold">{cardInfo.metadata.title}</h2>
                  <span className="font-bold">
                    {cardInfo.metadata.subtitle}
                  </span>
                  <p>{cardInfo.metadata.description}</p>

                  <div className="flex justify-between space-x-6 mt-4">
                    <div className="w-full px-6 py-3 flex justify-between items-center bg-gradient-to-r from-[rgba(250,250,250,0.3)] to-blue-900 rounded-xl mb-6">
                      <h3 className="text-lg uppercase tracking-widest">
                        Estado
                      </h3>
                      <p>{cardInfo.status}</p>
                    </div>

                    <div className="w-full px-6 py-3 flex justify-between items-center bg-gradient-to-r from-[rgba(250,250,250,0.3)] to-blue-900 rounded-xl mb-6">
                      <h3 className="text-lg uppercase tracking-widest">
                        Días Restantes
                      </h3>
                      <p>{cardInfo.validity_time}</p>
                    </div>
                  </div>

                  {cardInfo.value.backing === "units" ? (
                    <div>
                      <div>Total de Unidades {cardInfo.value.initialUnits}</div>
                      <div>
                        Valor de Unidad ${" "}
                        {cardInfo.value.equivalentValuePerUnit}
                      </div>
                      <div>
                        Unidades Restantes {cardInfo.value.remainingUnits}
                      </div>
                    </div>
                  ) : (
                    <div className="w-full px-6 py-3 flex justify-between items-center bg-gradient-to-r from-[rgba(250,250,250,0.3)] to-blue-900 rounded-xl">
                      <p className="text-2xl">{cardInfo.value.initialValue}$</p>
                      {/* Monto Restantes $ {cardInfo.value.remainingValue} */}

                      <button
                        className="btn-primary"
                        onClick={() => navigate("/confirmar")}
                      >
                        Continuar
                      </button>
                    </div>
                  )}
                </div>
              </>
            )}
            {/* <button style={{ backgroundColor: '#00ff0c', marginLeft: 10, borderRadius: 4, width: 150, height: 50 }} onClick={() => navigate('/confirmar')}>Continuar</button> */}
          </div>
        )}
        {!card && (
          <section className="flex justify-center my-12">
            <svg
              className="animate-spin -ml-1 mr-3 h-10 w-10 text-blue"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          </section>
        )}
      </div>
    </main>
    // </div>
  );
}
