
const Footer = () => {
  return (
      <footer className='text-gray-700 p-3 text-center text-sm'>
        <p>© 2022 Mintcard - J-501231230</p>
      </footer>
  )
}

export default Footer
