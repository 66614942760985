/* This example requires Tailwind CSS v2.0+ */
import { Disclosure } from '@headlessui/react'
import { MenuIcon, XIcon } from '@heroicons/react/outline'
import logo_lg from '../assets/img/Mintcard-Positive.svg'

const url_main = 'https://mintcard.net'
const navigation = [
  { name: 'Incluir Comercio', href: url_main, current: true },
  { name: 'Sobre Nosotros', href: url_main, current: true },
  { name: '¿Necesitas ayuda?', href: url_main, current: false },
  { name: 'Contacto', href: url_main, current: false },
]

const Navbar = () => {
  return (
    <>
      <div className="min-h-full">
        <Disclosure as="nav" className="bg-white shadow-sm">
          {({ open }) => (
            <>
              <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="flex justify-between h-16">
                  <div className="flex-shrink-0 flex items-center">
                    {/* <img
                        className="block lg:hidden h-8 w-auto"
                        src={logo_sm}
                        alt="Workflow"
                      /> */}
                    <a href={url_main} target="_blank" rel="noopener noreferrer">
                      <img
                        className="block h-8 w-auto"
                        src={logo_lg}
                        alt="Workflow"
                      />
                    </a>
                  </div>

                  <div className="hidden sm:-my-px sm:ml-6 sm:flex sm:space-x-8">
                    {navigation.map((item) => (
                      <a
                        target="_blank"
                        key={item.name}
                        href={item.href}
                        className='border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium'
                        aria-current={item.current ? 'page' : undefined}
                      >
                        {item.name}
                      </a>
                    ))}
                  </div>

                  <div className="-mr-2 flex items-center sm:hidden">
                    <Disclosure.Button className="bg-white inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100">
                      <span className="sr-only">Open main menu</span>
                      {open ? (
                        <XIcon className="block h-6 w-6" aria-hidden="true" />
                      ) : (
                        <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                      )}
                    </Disclosure.Button>
                  </div>
                </div>
              </div>

              <Disclosure.Panel className="sm:hidden">
                <div className="pt-4 pb-3 border-t border-gray-200">
                  {navigation.map((item) => (
                    <Disclosure.Button
                      key={item.name}
                      as="a"
                      target="_blank"
                      href={item.href}
                      className="block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100"
                    >
                      {item.name}
                    </Disclosure.Button>
                  ))}
                </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      </div>
    </>
  )
}

export default Navbar
