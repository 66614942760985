import React from 'react'
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import Home from '../views/home/home'
import CardDetails from "../views/cards/cardDetails";
import ActiveCard from "../views/cards/activeCard";
import Action from "../views/action/action";
import ActionCard from "../views/cards/actionCard";
import Confirmation from "../views/action/confirmation";
import Error from "../views/error/error";

const AppRouter = () => {
    return (
        <BrowserRouter>
            <Navbar />
            <Routes>
                <Route exact path="/" element={<Home />} />
                <Route exact path="/:id" element={<CardDetails />} />
                <Route exact path="/activar/:id" element={<ActiveCard />} />
                <Route exact path="/acciones" element={<Action />} />
                <Route exact path="/validar" element={<ActionCard />} />
                <Route exact path="/confirmar" element={<Confirmation />} />
                <Route exact path="/error" element={<Error />} />
            </Routes>
            <Footer />
        </BrowserRouter>
    );

}

export default AppRouter