import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams, useNavigate, Link } from "react-router-dom";
import { CARD } from "../../data/card";
import { firebase } from "../../config/firebase";
// import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

// export const CardDetails = () => {
export default function CardDetails() {
  const { id } = useParams();
  const validateBranchReducer = useSelector(
    (state) => state.validateBranchReducer
  );
  const [card, setCard] = useState(CARD);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  const loadDataOnlyOnce = () => {
    console.log(id);
    if (isLoading) {
      let info = id.split("-");
      if (info.length !== 3) {
        navigate("/");
      } else {
        const validateCard = firebase.functions().httpsCallable("validateCard");

        validateCard({ client: info[0], idLote: info[1], code: info[2] })
          .then((res) => {
            console.log(res);
            setCard(res.data);
            setIsLoading(false);
          })
          .catch((err) => {
            console.log(err);
            setIsLoading(false);
          });
      }
    }
  };

  useEffect(() => {
    if (validateBranchReducer.code === undefined) navigate("/");
    loadDataOnlyOnce();
  }, []);

  return (
    <main className="flex flex-col items-center px-3 py-6">
      <div className="card w-full md:w-1/2">
        {card.code == "" && (
          <section className="flex justify-center my-12">
            <svg
              className="animate-spin -ml-1 mr-3 h-10 w-10 text-blue"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          </section>
        )}

        {card.code !== "" && (
          <>
            <div className="grid md:grid-cols-2 px-4 pt-4">
              <h1 className="text-center font-bold text-2xl mb-5 col-span-2">
                {card.code}
              </h1>

              <div>
                <div className="mb-3">
                  <h3 className="text-lg uppercase tracking-widest">
                    Adquirido por
                  </h3>
                  <p>{card.buyer_information.name}</p>
                </div>

                <div className="mb-3">
                  <h3 className="text-lg uppercase tracking-widest">C.I</h3>
                  <p>{card.buyer_information.id}</p>
                </div>

                <div className="mb-3">
                  <h3 className="text-lg uppercase tracking-widest">Email</h3>
                  <p>{card.buyer_information.email}</p>
                </div>

                <div className="mb-3">
                  <h3 className="text-lg uppercase tracking-widest">
                    Teléfono
                  </h3>
                  <p>{card.buyer_information.phone}</p>
                </div>
              </div>

              {card.buyer_information.gift.isGift && (
                <div>
                  <div className="mb-3">
                    <h3 className="text-lg uppercase tracking-widest">
                      Regalada a
                    </h3>
                    <p>{card.buyer_information.gift.recipientName}</p>
                  </div>

                  <div className="mb-3">
                    <h3 className="text-lg uppercase tracking-widest">C.I</h3>
                    <p>{card.buyer_information.gift.recipientId}</p>
                  </div>

                  <div className="mb-3">
                    <h3 className="text-lg uppercase tracking-widest">Email</h3>
                    <p>{card.buyer_information.gift.recipientEmail}</p>
                  </div>
                </div>
              )}

              <ul role="list" className="mt-6 col-span-2">
                {card.ledger.map((value, index) => (
                  <li key={value.index}>
                    <div className="relative pb-8">
                      {index !== card.ledger.length - 1 ? (
                        <span
                          className="absolute top-4 left-4 h-full w-0.5 bg-gray-200 -ml-2"
                          aria-hidden="true"
                        />
                      ) : null}

                      <div className="relative flex items-center space-x-4">
                        <div>
                          <span className="h-4 w-4 rounded-full flex items-center justify-center ring-4 bg-blue-500">
                            <div
                              className="h-2 w-2 bg-blue-500"
                              aria-hidden="true"
                            ></div>
                          </span>
                        </div>

                        <div className="flex items-center space-x-4 w-full">
                          <p>{value.eventName} </p>

                          <div className="text-right text-sm whitespace-nowrap text-gray-500">
                            <time
                              dateTime={new Date(
                                value.eventOccuredOn._seconds * 1000
                              ).toLocaleString()}
                            >
                              {new Date(
                                value.eventOccuredOn._seconds * 1000
                              ).toLocaleString()}
                            </time>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>

            <div className="bg-blue-900 p-4 text-white mt-5 rounded-b-xl">
              <h2 className="font-bold">{card.metadata.title}</h2>
              <span className="font-bold">{card.metadata.subtitle}</span>
              <p>{card.metadata.description}</p>

              <div className="flex justify-between space-x-6 mt-4">
                <div className="w-full px-6 py-3 flex justify-between items-center bg-gradient-to-r from-[rgba(250,250,250,0.3)] to-blue-900 rounded-xl mb-6">
                  <h3 className="text-lg uppercase tracking-widest">Estado</h3>
                  <p>{card.status}</p>
                </div>

                <div className="w-full px-6 py-3 flex justify-between items-center bg-gradient-to-r from-[rgba(250,250,250,0.3)] to-blue-900 rounded-xl mb-6">
                  <h3 className="text-lg uppercase tracking-widest">
                    Días Restantes
                  </h3>
                  <p>{card.validity_time}</p>
                </div>
              </div>

              {card.value.backing === "units" ? (
                <div>
                  <div>Total de Unidades {card.value.initialUnits}</div>
                  <div>
                    Valor de Unidad $ {card.value.equivalentValuePerUnit}
                  </div>
                  <div>Unidades Restantes {card.value.remainingUnits}</div>
                </div>
              ) : (
                <div className="w-full px-6 py-3 flex justify-between items-center bg-gradient-to-r from-[rgba(250,250,250,0.3)] to-blue-900 rounded-xl">
                  <p className="text-2xl">{card.value.initialValue}$</p>
                  {/* Monto Restantes $ {card.value.remainingValue} */}

                  <button
                    className="btn-primary"
                    onClick={() => navigate("/confirmar")}
                  >
                    Continuar
                  </button>
                </div>
              )}
            </div>
          </>
        )}
      </div>

      <Link to="/validar" className="link-primary mt-4">
        Volver
      </Link>
    </main>
  );
}
